* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #111;
  line-height: 1.5;
  font-size: 14px;
  background: #f7f7f9;
  min-height: 100vh;
  margin: 0 auto !important;
  max-width: 7.5rem;
}

/* flex 布局 */

.flex {
  display: flex;
  flex-direction: row;
}

.flex_row {
  display: flex;
  flex-direction: row!important;
}

.flex_column {
  display: flex;
  flex-direction: column!important;
}

.flex_wrap {
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row_center,
.center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.row_between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.row_center_between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.col_center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.in1line {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.flex_1 {
  flex: 1;
}

.flex_2 {
  flex: 2;
}

.cl_nine {
  color: #999;
}

i {
  font-style: normal;
}

.ml_5 {
  margin-left: 0.05rem;
}

.ml_10 {
  margin-left: 0.1rem;
}

.ml_15 {
  margin-left: 0.15rem;
}

.ml_20 {
  margin-left: 0.2rem;
}

.mt_5 {
  margin-top: 0.05rem;
}

.mt_8 {
  margin-top: 0.08rem;
}

.mt_10 {
  margin-top: 0.1rem;
}

.mt_12 {
  margin-top: 0.12rem;
}

.mt_15 {
  margin-top: 0.15rem;
}

.mt_20 {
  margin-top: 0.2rem;
}

.p_10 {
  padding: 0.1rem;
}

.px_15 {
  padding-left: 0.15rem;
  padding-right: 0.15rem;
}

.py_10 {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}

.py_15 {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}

.pt_15 {
  padding-top: 0.15rem;
}

.pb_10 {
  padding-bottom: 0.1rem;
}

.cl_white {
  color: #fff;
}

.cl_three {
  color: #333;
}

.cl_six {
  color: #666;
}

.cl_yellow {
  color: #faff40;
}

.cl_red {
  color: #ed424a;
}

.bold {
  font-weight: bold;
}

.fs20 {
  font-size: 0.2rem;
}

.fs18 {
  font-size: 0.18rem;
}

.fs16 {
  font-size: 0.16rem;
}

.fs15 {
  font-size: 0.15rem;
}

.fs12 {
  font-size: 0.12rem;
}

.fs10 {
  font-size: 0.1rem;
}

.bg_white {
  background: #fff;
}

.banner {
  width: 100%;
  height: 2.9rem;
  position: relative;
}

.banner .people_num {
  z-index: 100;
  padding: 0 0.25rem;
  color: #fff;
  left: 0.5rem;
  right: 0.5rem;
  height: 0.35rem;
  position: absolute;
  top: 0.25rem;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0.4) 70%, rgba(0, 0, 0, 0) 100%);
}

.index_price {
  height: 0.6rem;
  background-color: #ff1b3c;
  color: #fff;
  position: relative;
}

.index_price .left {
  padding-left: 0.1rem;
  display: flex;
  height: 0.6rem;
  align-items: center;
}

.index_price .left .current {
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.01rem;
}

.index_price .left .orign {
  text-decoration: line-through;
  margin-left: 0.15rem;
}

.index_price .time {
  color: #ff1a3e;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background: #fef282;
  width: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.index_price .con {
  margin-top: 0.03rem;
}

.index_price .con span {
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 0.01rem;
  border-radius: 0.02rem;
  margin: 0 0.02rem;
}

.index_price .price_con {
  font-size: 0.35rem;
  margin-bottom: -0.03rem;
}

.index_title {
  background: #fff;
  padding: 0.15rem;
}

.index_title .title {
  line-height: 1.4;
}

.index_title .title i {
  display: inline-block;
  color: #fff;
  background: #fe2538;
  padding: 0.04rem 0.05rem;
  line-height: 1;
  border-radius: 0.03rem;
}

.index_title .num .line {
  width: 0.01rem;
  height: 0.15rem;
  background: #eee;
}

.index_desc {
  padding: 0.25rem 0.2rem;
  background: #fff;
}

.index_desc .con {
  margin-top: 0.15rem;
}

.index_btn_fixed {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  height: 0.55rem;
}

.index_btn_fixed {
  font-size: 0.15rem;
}

.index_btn_fixed .red {
  height: 100%;
  background: #fe113b;
  color: #fff;
}

.index_footer {
  height: 0.65rem;
}

.pay_container .price_box {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.1rem;
}

.group_bg {
  background: linear-gradient(180deg, #fff590, #fee441);
  height: 0.24rem;
  border-radius: 0.12rem;
  padding: 0 0.1rem;
  display: flex;
  align-items: center;
}

.group_bg.none {
  background: #fff;
  border: 0.01rem solid #fe113b;
}

.circle {
  width: 0.1rem;
  height: 0.1rem;
  background: -webkit-gradient(linear, left top, left bottom, from(#e8e8e8), to(#ccc));
  background: linear-gradient(180deg, #e8e8e8, #ccc);
  border: 0.01rem solid #fff;
  border-radius: 50%;
  margin-right: 0.1rem;
}

.pay_container .price_box {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.1rem;
}